import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67934ab5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "fallback-image"
}
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle([$setup.postionalStyles($setup.startX, $setup.startY, $setup.width, $setup.height), { padding: '1px' }]),
    ref: $setup.setElements($props.object, $props.index),
    class: _normalizeClass([$setup.MainStore.getCurrentElementsId.includes($setup.id) && 'active-elements']),
    onMousedown: _cache[0] || (_cache[0] = _withModifiers($event => ($setup.handleMouseDown($event, $props.object)), ["left"])),
    onDblclick: _cache[1] || (_cache[1] = _withModifiers($event => ($setup.handleDblClick($event, $props.object)), ["stop"])),
    onMouseup: _cache[2] || (_cache[2] = $event => ($setup.handleMouseUp($event, $props.object)))
  }, [
    ($setup.isDynamic ? $setup.image && Boolean($setup.image.value) : $setup.image && Boolean($setup.image.file_url))
      ? (_openBlock(), _createElementBlock("div", {
          style: _normalizeStyle([
				$setup.widthHeightStyle($setup.width, $setup.height),
				$setup.style,
				$setup.style.backgroundColor == '' && { backgroundColor: 'transparent' },
				`background-image: url('${$setup.isDynamic ? $setup.image.value : $setup.image.file_url}');`,
			]),
          class: _normalizeClass(['image', $setup.classes]),
          key: $setup.id
        }, null, 6 /* CLASS, STYLE */))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            ($setup.width >= 30 || $setup.height >= 30)
              ? (_openBlock(), _createBlock($setup["IconsUse"], {
                  draggable: false,
                  size: 18,
                  name: "es-line-image-alt1",
                  key: "es-line-image-alt1"
                }))
              : _createCommentVNode("v-if", true),
            (
						($setup.width >= 120 || $setup.height >= 120) && $setup.isDynamic
							? $setup.image && !Boolean($setup.image.value)
							: $setup.image && !Boolean($setup.image.file_url)
					)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($setup.isDynamic ? "Image not Linked" : "Unable to load Image :("), 1 /* TEXT */))
              : ($setup.width >= 120 || $setup.height >= 120)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Please Double click to select Image"))
                : _createCommentVNode("v-if", true)
          ])
        ])),
    (
				$setup.MainStore.activeControl == 'mouse-pointer' &&
				$setup.MainStore.getCurrentElementsId.includes($setup.id)
			)
      ? (_openBlock(), _createBlock($setup["BaseResizeHandles"], { key: 2 }))
      : _createCommentVNode("v-if", true)
  ], 38 /* CLASS, STYLE, NEED_HYDRATION */))
}