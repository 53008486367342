import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e386a72e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "canvas" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "preview-container",
      class: "preview-container",
      style: _normalizeStyle([$setup.MainStore.getPageSettings, 'height: unset;'])
    }, null, 4 /* STYLE */)
  ]))
}