import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle([
			$setup.style,
			$setup.style.backgroundColor == '' && { backgroundColor: 'transparent' },
			$setup.postionalStyles($setup.startX, $setup.startY, $setup.width, $setup.height),
		]),
    class: _normalizeClass([
			'rectangle',
			{ 'active-elements': $setup.MainStore.getCurrentElementsId.includes($setup.id) },
			$setup.classes,
		]),
    ref: $setup.setElements($props.object, $props.index),
    onMousedown: _cache[0] || (_cache[0] = _withModifiers($event => ($setup.handleMouseDown($event, $props.object, $props.index)), ["left","stop"])),
    onMouseup: _cache[1] || (_cache[1] = _withModifiers($event => ($setup.handleMouseUp($event, $props.object, $props.index)), ["left"]))
  }, [
    (
				$setup.MainStore.activeControl == 'mouse-pointer' &&
				$setup.MainStore.getCurrentElementsId.includes($setup.id)
			)
      ? (_openBlock(), _createBlock($setup["BaseResizeHandles"], { key: 0 }))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.object.childrens, (object, index) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(
					object.type == 'text'
						? $setup.isComponent[object.type][object.isDynamic ? 'dynamic' : 'static']
						: $setup.isComponent[object.type]
				), _normalizeProps(_mergeProps({
        key: object.id
      }, { object, index })), null, 16 /* FULL_PROPS */))
    }), 128 /* KEYED_FRAGMENT */))
  ], 38 /* CLASS, STYLE, NEED_HYDRATION */))
}