import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9d0cc001"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["contenteditable", "onKeydown", "innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    onDblclick: _cache[1] || (_cache[1] = _withModifiers($event => ($setup.handleDblClick($event, $props.object, $props.index)), ["stop"])),
    onMousedown: _cache[2] || (_cache[2] = _withModifiers($event => ($setup.handleMouseDown($event, $props.object, $props.index)), ["left"])),
    onMouseup: $setup.handleMouseUp,
    style: _normalizeStyle([
			$setup.postionalStyles($setup.startX, $setup.startY, $setup.width, $setup.height),
			!$setup.isFixedSize && {
				width: 'fit-content',
				height: 'fit-content',
				maxWidth:
					$setup.MainStore.page.width -
					$setup.MainStore.page.marginLeft -
					$setup.MainStore.page.marginRight -
					$setup.startX +
					'px',
			},
		]),
    class: _normalizeClass($setup.MainStore.getCurrentElementsId.includes($setup.id) ? 'active-elements' : 'text-hover'),
    ref: $setup.setElements($props.object, $props.index),
    key: $setup.id
  }, [
    ($setup.type == 'text')
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          contenteditable: $setup.contenteditable,
          onKeydown: _withModifiers($setup.handleKeyDown, ["stop"]),
          onFocus: $setup.handleFocus,
          onBlur: $setup.handleBlur,
          onKeyup: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
          style: _normalizeStyle([
				$setup.style,
				$setup.style.backgroundColor == '' && { backgroundColor: 'transparent' },
				$setup.widthHeightStyle($setup.width, $setup.height),
				!$setup.isFixedSize && {
					width: 'fit-content',
					height: 'fit-content',
					maxWidth:
						$setup.MainStore.page.width -
						$setup.MainStore.page.marginLeft -
						$setup.MainStore.page.marginRight -
						$setup.startX +
						'px',
				},
			]),
          class: _normalizeClass(['staticText', $setup.classes]),
          "data-placeholder": "",
          innerHTML: $setup.parsedValue
        }, null, 46 /* CLASS, STYLE, PROPS, NEED_HYDRATION */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    (!$setup.contenteditable && $setup.MainStore.getCurrentElementsId.includes($setup.id))
      ? (_openBlock(), _createBlock($setup["BaseResizeHandles"], { key: 1 }))
      : _createCommentVNode("v-if", true)
  ], 38 /* CLASS, STYLE, NEED_HYDRATION */))
}