import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "primary-actions" }
const _hoisted_2 = { class: "secondary-actions" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "properties-container",
    onKeydown: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"])),
    onKeyup: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"])),
    ref: 
			(el) => {
				$setup.MainStore.propertiesContainer = el;
			}
		
  }, [
    _createVNode($setup["Icons"], { style: {"display":"none"} }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "btn btn-sm",
        onClick: _cache[0] || (_cache[0] = 
					(event) => {
						if ($setup.MainStore.mode == 'pdfSetup') {
							$setup.MainStore.mode = 'editing';
						} else {
							$setup.MainStore.mode = 'pdfSetup';
						}
						event.target.blur();
					}
				)
      }, _toDisplayString($setup.MainStore.mode == "pdfSetup" ? "Exit" : "Header / Footer"), 1 /* TEXT */),
      _createElementVNode("button", {
        class: "btn btn-sm btn-primary",
        onClick: _cache[1] || (_cache[1] = 
					(event) => {
						if ($setup.MainStore.mode == 'pdfSetup') {
							$setup.MainStore.mode = 'editing';
						}
						$setup.ElementStore.saveElements();
						event.target.blur();
					}
				)
      }, " Save ")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btn btn-sm add-data-button",
        onClick: _cache[2] || (_cache[2] = (event) => ($setup.MainStore.openJinjaModal = true))
      }, " Manage Custom Data ")
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.MainStore.propertiesPanel, (section) => {
      return (_openBlock(), _createBlock($setup["AppPropertiesPanelSection"], {
        section: section,
        key: section.title ? section.title.replace(' ', '') : section.name
      }, null, 8 /* PROPS */, ["section"]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 544 /* NEED_HYDRATION, NEED_PATCH */))
}