import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["onMousedown", "onMouseup"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "canvas",
      id: "canvas",
      style: _normalizeStyle([$setup.MainStore.mode == 'editing' && { cursor: $setup.MainStore.cursor }])
    }, [
      _createElementVNode("div", {
        class: "main-container",
        ref: 
					(el) => {
						$setup.MainStore.mainContainer = el;
					}
				,
        style: _normalizeStyle($setup.MainStore.getPageSettings),
        onMousedown: _withModifiers($setup.handleMouseDown, ["left"]),
        onMousemove: $setup.handleMouseMove,
        onMouseleave: $setup.handleMouseLeave,
        onMouseup: _withModifiers($setup.handleMouseUp, ["left"])
      }, [
        ($setup.MainStore.mode == 'pdfSetup')
          ? (_openBlock(), _createBlock($setup["AppPdfSetup"], { key: 0 }))
          : _createCommentVNode("v-if", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.ElementStore.Elements, (object, index) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent(
							object.type == 'text'
								? $setup.isComponent[object.type][object.isDynamic ? 'dynamic' : 'static']
								: $setup.isComponent[object.type]
						), _normalizeProps(_mergeProps({
            key: object.id
          }, { object, index })), null, 16 /* FULL_PROPS */))
        }), 128 /* KEYED_FRAGMENT */))
      ], 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_1),
      (!!$setup.MainStore.openModal)
        ? (_openBlock(), _createBlock($setup["AppWidthHeightModal"], {
            key: 0,
            openModal: $setup.MainStore.openModal
          }, null, 8 /* PROPS */, ["openModal"]))
        : _createCommentVNode("v-if", true),
      (!!$setup.MainStore.openDynamicModal || !!$setup.MainStore.openTableColumnModal?.table)
        ? (_openBlock(), _createBlock($setup["AppDynamicTextModal"], {
            key: 1,
            openDynamicModal: 
					$setup.MainStore.openDynamicModal || $setup.MainStore.openTableColumnModal.column
				,
            table: 
					$setup.MainStore.openTableColumnModal ? $setup.MainStore.openTableColumnModal.table : null
				
          }, null, 8 /* PROPS */, ["openDynamicModal", "table"]))
        : _createCommentVNode("v-if", true),
      (!!$setup.MainStore.openBarcodeModal)
        ? (_openBlock(), _createBlock($setup["AppBarcodeModal"], {
            key: 2,
            openBarcodeModal: $setup.MainStore.openBarcodeModal
          }, null, 8 /* PROPS */, ["openBarcodeModal"]))
        : _createCommentVNode("v-if", true),
      (!!$setup.MainStore.openImageModal)
        ? (_openBlock(), _createBlock($setup["AppImageModal"], {
            key: 3,
            openImageModal: $setup.MainStore.openImageModal
          }, null, 8 /* PROPS */, ["openImageModal"]))
        : _createCommentVNode("v-if", true),
      (!!$setup.MainStore.openJinjaModal)
        ? (_openBlock(), _createBlock($setup["AppUserProvidedJinjaModal"], { key: 4 }))
        : _createCommentVNode("v-if", true)
    ], 4 /* STYLE */)), [
      [$setup["vMarquee"], $setup.marqueeOptions],
      [_vShow, $setup.MainStore.mode != 'preview']
    ]),
    ($setup.MainStore.mode == 'preview')
      ? (_openBlock(), _createBlock($setup["AppPreviewPdf"], { key: 0 }))
      : _createCommentVNode("v-if", true)
  ]))
}